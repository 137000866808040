<template>
  <div class="exit_email">
    <back-step
      :currentTxt="`${$t('home.personal.bandLoginPaw.dlmm')}`"
      :goList="[
        {
          url: '/',
          name: `${$t('home.personal.personal.zy')}`
        },
        {
          url: '/home/personal',
          name: `${$t('home.personal.personal.grzx')}`
        }
      ]"
    />
    <div v-if="!isExitOk" class="children_con">
      <h5 class="c_con_title">{{ $t("home.personal.bandLoginPaw.dlmm") }}</h5>
      <ul class="c_form">
        <li class="f_single">
          <span class="s_label"
            >{{ $t("home.personal.bandLoginPaw.sjh") }}:
          </span>
          <div class="iup_box">
            <input v-model="params.mobile" disabled type="number" />
          </div>
        </li>
        <li class="f_single">
          <span class="s_label"
            >{{ $t("home.personal.bandLoginPaw.yzm") }}:
          </span>
          <div class="iup_box">
            <input
              v-model="params.smsCaptcha"
              :placeholder="$t('login.retrievePaw.hqyzm')"
              type="number"
            />
            <span
              :class="isYzm ? 'yzm_sp_active' : ''"
              class="yzm_sp"
              @click="getYzm"
              >{{
                isYzm
                  ? `${$t("home.personal.bandEmail.cxhq")} ${yzmTime}s`
                  : `${$t("home.personal.bandEmail.hqyzm")}`
              }}</span
            >
          </div>
        </li>
        <li class="f_single">
          <span class="s_label"
            >{{ $t("home.personal.bandLoginPaw.dlmm") }}:
          </span>
          <div class="iup_box">
            <input
              v-model="params.loginPwd"
              :placeholder="$t('login.login.srmm')"
              autocomplete="new-password"
              type="password"
            />
          </div>
        </li>
        <li class="f_single">
          <span class="s_label"
            >{{ $t("home.personal.bandLoginPaw.zcqr") }}:
          </span>
          <div class="iup_box">
            <input
              v-model="params.loginPwd01"
              :placeholder="$t('login.login.srmm')"
              autocomplete="new-password"
              type="password"
            />
          </div>
        </li>
      </ul>
      <div class="c_foo">
        <div class="cancel_btn flex-x-y-c curp" @click="goBack">
          {{ $t("common.message.qx") }}
        </div>
        <div class="confirm_btn flex-x-y-c curp" @click="submitForm">
          {{ $t("common.message.qr") }}
        </div>
      </div>
    </div>
    <exit-success
      v-else
      :isExitOk="isExitOk"
      :successTit="`${$t('common.message.szcg')}`"
      :successTxt="`${$t('common.message.dlmmszcg')}`"
    />
    <full-loading v-show="isLoading" />
  </div>
</template>

<script>
import BackStep from "../../../cases/cases-detail/childrenPublic/backStep";
import ExitSuccess from "./exitSuccess";
import { notifyMsg, getUserId } from "common/js/util";
import { modifyLoginPaw, sendCaptcha } from "common/api/user";
import FullLoading from "components/full-loading/full-loading";
import { mapState } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
      isExitOk: false,
      isYzm: false,
      yzmTime: 59,
      interTime: null,
      params: {
        mobile: sessionStorage.getItem("userMobile") || "",
        smsCaptcha: "",
        loginPwd: "",
        loginPwd01: ""
      }
    };
  },
  methods: {
    getYzm() {
      if (this.isYzm) {
        return false;
      }
      const { mobile } = this.params;
      if (!mobile) {
        return notifyMsg(this, "warning", this.$t("common.message.qtxsjh"));
      } else if (!/^1[3|4|5|6|7|8|9]\d{9}$/.test(mobile)) {
        return notifyMsg(this, "warning", this.$t("common.message.qzqtxsjh"));
      }
      if (this.interTime) {
        clearInterval(this.interTime);
      }
      this.isLoading = true;
      sendCaptcha({
        bizType: "MODIFY_LOGIN_PWD",
        mobile
      })
        .then(() => {
          this.isLoading = false;
          this.isYzm = true;
          this.interTime = setInterval(() => {
            if (this.yzmTime < 1) {
              this.isYzm = false;
              this.yzmTime = 59;
              clearInterval(this.interTime);
            }
            this.yzmTime--;
          }, 1000);
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    submitForm() {
      const { mobile, smsCaptcha, loginPwd, loginPwd01 } = this.params;
      if (!mobile || !loginPwd || !smsCaptcha || !loginPwd01) {
        return notifyMsg(this, "warning", this.$t("common.message.qtxwz"));
      } else if (!/^1[3|4|5|6|7|8|9]\d{9}$/.test(mobile)) {
        return notifyMsg(this, "warning", this.$t("common.message.qzqtxsjh"));
      } else if (loginPwd !== loginPwd01) {
        return notifyMsg(this, "warning", this.$t("common.message.mmbyz"));
      } else {
        this.isLoading = true;
        modifyLoginPaw({
          id: getUserId(),
          mobile,
          smsCaptcha,
          loginPwd
        })
          .then(() => {
            this.isLoading = false;
            this.isExitOk = true;
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
    goBack() {
      window.history.go(-1);
    }
  },
  components: {
    BackStep,
    ExitSuccess,
    FullLoading
  },
  computed: {
    ...mapState({
      language: state => state.language
    })
  },
  beforeDestroy() {
    if (this.interTime) {
      clearInterval(this.interTime);
    }
  }
};
</script>

<style lang="scss" scoped>
.exit_email {
  width: 13.44rem;
  margin: 0 auto;
  padding-top: 40px;

  .children_con {
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    .c_con_title {
      font-size: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 0.6rem;
      color: $main_theme_color_333;
      border-bottom: 1px solid #e5e5e5;
    }

    .c_form {
      width: 5.44rem;
      margin-top: 0.6rem;
      margin-bottom: 0.6rem;

      .f_single {
        display: flex;
        align-items: center;
        margin-bottom: 0.4rem;

        .s_label {
          width: 0.8rem;
          margin-right: 0.1rem;
          white-space: nowrap;
          display: flex;
          flex-flow: row-reverse;
          font-size: 16px;
          color: $main_theme_color_333;
        }

        .iup_box {
          flex: 1;
          display: flex;
          align-items: center;
          position: relative;
          height: 0.52rem !important;

          input {
            flex: 1;
            border-radius: 4px;
            border: 0.01rem solid #bbbbbb;
            padding: 16px 20px;
            font-size: 14px;
            color: $main_theme_color_333;
          }

          .yzm_sp {
            margin-left: 0.1rem;
            width: 1.2rem;
            height: 0.4rem;
            border-left: 1px solid #e5e5e5;
            color: $main_theme_color;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            cursor: pointer;
          }

          .yzm_sp_active {
            color: $main_theme_color_666;
            cursor: default;
          }
        }
      }
    }
  }

  .c_foo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.64rem;

    .cancel_btn {
      width: 176px;
      height: 42px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #bbbbbb;
      font-size: 16px;
      color: $main_theme_color_333;
    }

    .confirm_btn {
      width: 176px;
      height: 42px;
      background: $main_theme_color;
      border-radius: 6px;
      font-size: 16px;
      margin-left: 32px;
      color: #fff;
    }
  }
}
</style>
